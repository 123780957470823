import { Feature } from "geojson";
import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { MapModuleLayer } from "../../../types/store-schema";
import { isModelLayer } from "../../../utilities/state-helpers";
import { getLayerMapOrderValue } from "./layer-ordering-generic-helpers";
import { internalMapLayerIds } from "../../constants";

/**
 * Only used when a 3d feature is below the mouse
 *
 * Merges the query rendered features from models and from mapbox and ensure they are in the correct order
 */
export function orderQueryRenderFeatures(queried2DFeatures: Feature[], queried3DFeatures: Feature[], layerMap: { [layerId: string]: MapModuleLayer }): Feature[] {
    return [...queried2DFeatures, ...queried3DFeatures].sort((a, b) => {
        const aLayerId = a?.properties?.layerid;
        // Ensure that internal layers are always on top
        if (internalMapLayerIds.includes(aLayerId)) {
            return -1;
        }

        const bLayerId = b?.properties?.layerid;
        // Ensure that internal layers are always on top
        if (internalMapLayerIds.includes(bLayerId)) {
            return 1;
        }

        const layerA = layerMap[aLayerId];
        // If layer is null then it is a CAD or Map background layer
        if (layerA == null) {
            return 1;
        }

        const layerB = layerMap[bLayerId];
        // If layer is null then it is a CAD or Map background layer
        if (layerB == null) {
            return -1;
        }

        let aMapOrderValue = getLayerMapOrderValue(layerA);
        // If map order value is 0, it means it has not been order
        if (aMapOrderValue === 0 && !isModelLayer(layerA)) {
            // Check if layer is above/below model layers and change order value accordingly
            aMapOrderValue = isLayerAboveModel(layerA) ? 0.1 : -0.1;
        }

        let bMapOrderValue = getLayerMapOrderValue(layerB);
        // If map order value is 0, it means it has not been order
        if (bMapOrderValue === 0 && !isModelLayer(layerB)) {
            // Check if layer is above/below model layers and change order value accordingly
            bMapOrderValue = isLayerAboveModel(layerB) ? 0.1 : -0.1;
        }

        return bMapOrderValue - aMapOrderValue;
    });
}

/** Is layer a icon, point or 3d area */
function isLayerAboveModel(layer: MapModuleLayer) {
    return (
        layer?.styleType === StyleType.Icon ||
        layer?.styleType === StyleType.Point ||
        (layer?.styleType === StyleType.Area && getStaticStyleValue(layer.areaStyle.dimension) === AreaDimension.Three)
    );
}
