import { featureCollection } from "@turf/helpers";
import center from "@turf/center";
import transformRotate from "@turf/transform-rotate";
import { Feature, Position, FeatureCollection } from "geojson";
import { AnySupportedGeometry } from "@iventis/map-types";
import { rotateHandleLayerId } from "../bridge/constants";
import { ClickEvent, CompositionMapObject, Listener, MoveEvent, TypedFeature } from "../types/internal";
import { pointAlongLine, length, unitVectorToAngle } from "./vectors";

export function explodeNodes(features: Feature<AnySupportedGeometry>[]) {
    const geometries = features.map((f) => f.geometry.coordinates);

    let exploded = geometries;
    do {
        exploded = exploded.flat() as Position[][];
    } while (exploded.find((i) => typeof i !== "number"));

    const recombine = [];
    for (let i = 0; i < exploded.length; i += 2) {
        recombine.push([exploded[i], exploded[i + 1]]);
    }
    return recombine;
}

export function documentEventOnce(type: string, listener: (event) => void): { remove: () => void } {
    const wrapped = (e) => {
        listener(e);
        document.removeEventListener(type, wrapped);
    };
    document.addEventListener(type, wrapped);

    return {
        remove: () => {
            document.removeEventListener(type, wrapped);
        },
    };
}

export enum RotatorEvent {
    START = "START",
    DRAG = "DRAG",
    RELEASE = "RELEASE",
    ENTER = "ENTER",
    LEAVE = "LEAVE",
    CLICK = "CLICK",
}

interface ListenerPattern {
    [RotatorEvent.START]: (() => void)[];
    [RotatorEvent.DRAG]: ((objects: CompositionMapObject[]) => void)[];
    [RotatorEvent.RELEASE]: ((objects: CompositionMapObject[]) => void)[];
    [RotatorEvent.ENTER]: (() => void)[];
    [RotatorEvent.LEAVE]: (() => void)[];
    [RotatorEvent.CLICK]: (() => void)[];
}

export class Rotator {
    /*
        Responsible for providing interaction with rotation
        Displays handle and deals with mouse events
        Outputs rotational difference
    */
    private centroidXY: [number, number];

    private centroidLngLat: [number, number];

    private rotationRadius: number;

    private listening: ListenerPattern = {
        [RotatorEvent.START]: [],
        [RotatorEvent.DRAG]: [],
        [RotatorEvent.RELEASE]: [],
        [RotatorEvent.ENTER]: [],
        [RotatorEvent.LEAVE]: [],
        [RotatorEvent.CLICK]: [],
    };

    private currentAngle: number;

    private lastSentAngle = 0;

    private dragging = false;

    private readonly handleRadius: number = 8;

    private mouseMoveListener: Listener;

    private mouseDownListener: Listener;

    private mouseOverListener: Listener;

    private mouseClickListener: Listener;

    private overButton = false;

    private hasClicked = false;

    private featureCollection: FeatureCollection<AnySupportedGeometry>;

    constructor(
        private objects: CompositionMapObject[],
        private operations: {
            setRotatePosition: (x: number, y: number) => void;
            project: (lnglat: Position) => [number, number];
            onRotateMouseDown: (callback: () => void) => Listener;
            onMouseMove: (callback: (e: MoveEvent) => void) => Listener;
            onMouseClick: (callback: (e: ClickEvent) => void) => Listener;
        }
    ) {
        const features = objects.map((object) => object.geojson);
        this.featureCollection = featureCollection(features);
        const cent = center(this.featureCollection);

        const highestScreenCoord = explodeNodes(features)
            .map((i) => this.operations.project(i))
            .reduce((p, c) => (c[1] < p[1] ? c : p), [NaN, Infinity]);

        let paddedHandleY = highestScreenCoord[1] - 20;

        if (paddedHandleY < this.handleRadius + 20) {
            paddedHandleY = this.handleRadius + 20;
        }

        this.calcHandlePlacement(cent.geometry.coordinates as [number, number], paddedHandleY);
    }

    private calcHandlePlacement(centroidLngLat: [number, number], handleY: number) {
        const centroidXY = this.operations.project(centroidLngLat);
        this.centroidXY = [centroidXY[0], centroidXY[1]];
        this.centroidLngLat = centroidLngLat;
        this.rotationRadius = length([centroidXY[0], centroidXY[1]], [centroidXY[0], handleY]);
        this.displayHandle(centroidXY[0], handleY);
    }

    private displayHandle(x: number, y: number) {
        this.mouseDownListener = this.operations.onRotateMouseDown(() => {
            this.mouseMoveListener = this.operations.onMouseMove((event) => {
                this.onDrag(event);
            });
            documentEventOnce("mouseup", () => this.onRelease());
        });

        this.mouseClickListener = this.operations.onMouseClick(() => {
            this.hasClicked = true;
        });

        this.mouseOverListener = this.operations.onMouseMove((event) => {
            if (this.dragging) {
                return;
            }
            if (event.objects.some((object) => object.properties.layerid === rotateHandleLayerId)) {
                // Over button
                if (!this.overButton) {
                    this.overButton = true;
                    this.listening.ENTER.forEach((listener) => listener());
                }
            } else if (this.overButton) {
                this.overButton = false;
                this.listening.LEAVE.forEach((listener) => listener());
            }
        });

        this.operations.setRotatePosition(x, y);
    }

    private onDrag(e: { lng: number; lat: number }) {
        if (this.listening == null) {
            return;
        }

        if (!this.dragging) {
            this.listening.START.forEach((c) => c());
            this.dragging = true;
        }

        if (this.hasClicked) {
            this.onRelease();
            this.hasClicked = false;
            return;
        }

        const [cursorX, cursorY] = this.operations.project([e.lng, e.lat]);
        const point = pointAlongLine(this.centroidXY, [cursorX, cursorY], this.rotationRadius);

        // Make relative to the negative x-axis, this made it easier for me to understand
        const rawAngle = unitVectorToAngle(this.centroidXY, [cursorX, cursorY]) + 90;
        // Angle is currently between -90 and +270. Enforce a range of 0-360
        const positiveAngle = rawAngle < 0 ? 90 - rawAngle * -1 + 270 : rawAngle;
        // If another angle has already been sent, calculate relative to the new position
        const degrees = positiveAngle - this.lastSentAngle;
        this.currentAngle = degrees;

        this.operations.setRotatePosition(point[0], point[1]);
        const translatedObjects = this.getTranslation(this.centroidLngLat, degrees);
        this.listening.DRAG.forEach((c) => c(translatedObjects));
    }

    private onRelease() {
        if (!this.dragging) {
            return;
        }

        this.dragging = false;

        this.mouseMoveListener.remove();
        this.mouseDownListener.remove();
        this.mouseClickListener.remove();

        if (this.hasClicked) {
            // Create composition objects with no change in rotation
            const rotatedObjects: CompositionMapObject[] = this.featureCollection.features.map((feature: TypedFeature, index) => ({
                geojson: feature,
                layerId: this.objects[index].layerId,
                objectId: this.objects[index].objectId,
            }));
            this.listening.RELEASE.forEach((c) => c(rotatedObjects));

            return;
        }

        if (this.currentAngle === undefined) {
            throw new Error("Current angle was undefined");
        }

        // Record this angle as the new starting position for future rotations
        this.lastSentAngle = this.currentAngle + this.lastSentAngle;

        const rotatedObjects = this.getTranslation(this.centroidLngLat, this.currentAngle);

        this.listening.RELEASE.forEach((c) => c(rotatedObjects));
        this.currentAngle = undefined;
    }

    private getTranslation(centroid: [number, number], angle: number) {
        const rotatedFeatures = transformRotate(this.featureCollection, angle, { pivot: centroid });
        const rotatedObjects: CompositionMapObject[] = rotatedFeatures.features.map((feature: TypedFeature, index) => {
            const { rotation } = feature.properties;
            return {
                geojson: { ...feature, properties: { ...feature.properties, rotation: { ...rotation, z: rotation.z - angle } } },
                layerId: this.objects[index].layerId,
                objectId: this.objects[index].objectId,
            };
        });

        return rotatedObjects;
    }

    public destroy() {
        this.operations.setRotatePosition(undefined, undefined);
        this.mouseDownListener?.remove();
        this.mouseMoveListener?.remove();
        this.mouseOverListener?.remove();
        this.mouseClickListener.remove();
        delete this.listening;
    }

    public on<E extends RotatorEvent>(event: E, callback: ListenerPattern[keyof ListenerPattern][0]) {
        this.listening[event].push(callback as () => void);
        return this;
    }
}
