import { Interpreter, interpret, InterpreterStatus } from "xstate";
import GeoJSON from "geojson";
import { Listener } from "@iventis/types";
import { point } from "@turf/helpers";
import { send } from "xstate/lib/actions";
import { v4 as uuid } from "uuid";
import {
    ClickMachineEvent,
    CommentsMachineContext,
    CommentsMachineEvent,
    DeleteCommentEvent,
    DragEndEvent,
    ExitEvent,
    HoverOverCommentEvent,
    SelectCommentEvent,
} from "./comments-drawing-machine-types";
import { commentsDrawingMachineDesktop } from "./comments-drawing-desktop-machine";
import { commentsDrawingMachineMobile } from "./comments-drawing-mobile-machine";
import { ClickEvent, MapCursor, MoveEvent } from "../types/internal";
import { commentsLayerId } from "../bridge/constants";
import { EngineInterpreter } from "../bridge/engine-generic";
import { Typegen0 as MobileMachineState } from "./comments-drawing-mobile-machine.typegen";
import { Typegen0 as DesktopMachineState } from "./comments-drawing-desktop-machine.typegen";

export type CommentsDrawingModes = MobileMachineState["matchesStates"] | DesktopMachineState["matchesStates"];

export type SelectedMapComment = {
    userId: string;
    commentId: string;
    canvasCoordinates: [number, number];
    isNewComment?: boolean;
};

export enum CommentDrawingEvent {
    APPEND = "APPEND",
    PAN_START = "PAN_START",
    PAN_END = "PAN_END",
    DRAG_START = "DRAG_START",
    DRAG_END = "DRAG_END",
    SELECT_COMMENT = "SELECT_COMMENT",
    DELETE_COMMENT = "DELETE_COMMENT",
    DESTROY = "DESTROY",
    EXIT_COMPOSITION = "EXIT_COMPOSITION",
}

export interface CommentDrawingListeners {
    [CommentDrawingEvent.APPEND]: ((point: GeoJSON.Point, id: string) => void)[];
    [CommentDrawingEvent.PAN_START]: ((commentId: string) => void)[];
    [CommentDrawingEvent.PAN_END]: ((commentId: string) => void)[];
    [CommentDrawingEvent.DRAG_START]: ((commentId: string) => void)[];
    [CommentDrawingEvent.DRAG_END]: ((geometry: GeoJSON.Point, id: string, isValid: boolean) => void)[];
    [CommentDrawingEvent.SELECT_COMMENT]: ((oldId: string, newComment: SelectedMapComment | undefined) => void)[];
    [CommentDrawingEvent.DELETE_COMMENT]: ((commentId) => void)[];
    [CommentDrawingEvent.DESTROY]: ((selectedComment: SelectedMapComment | undefined) => void)[];
    [CommentDrawingEvent.EXIT_COMPOSITION]: ((keepCommentsMachineAlive: boolean) => void)[];
}

export class CommentsDrawing {
    private listeners: CommentDrawingListeners = {
        [CommentDrawingEvent.APPEND]: [],
        [CommentDrawingEvent.PAN_START]: [],
        [CommentDrawingEvent.PAN_END]: [],
        [CommentDrawingEvent.DRAG_START]: [],
        [CommentDrawingEvent.DRAG_END]: [],
        [CommentDrawingEvent.SELECT_COMMENT]: [],
        [CommentDrawingEvent.DELETE_COMMENT]: [],
        [CommentDrawingEvent.DESTROY]: [],
        [CommentDrawingEvent.EXIT_COMPOSITION]: [],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private machine: Interpreter<CommentsMachineContext, any, CommentsMachineEvent, any, any>;

    private panStartListener: Listener;

    private panEndListener: Listener;

    private zoomStartListener: Listener;

    private zoomEndListener: Listener;

    private mouseMoveListener: Listener;

    private mouseDragListener: Listener;

    private mouseDownListener: Listener;

    private mouseUpListener: Listener;

    private clickListener: Listener;

    private rightClickListener: Listener;

    private onContentUnderMouseChangedListener: Listener;

    private state: string;

    constructor(
        private operations: {
            onClick: (callback: (event: ClickEvent) => void) => Listener;
            onRightClick: (callback: (e: ClickEvent) => void) => Listener;
            onPanStart: (callback: () => void) => Listener;
            onPanEnd: (callback: () => void) => Listener;
            onContentUnderMouseChanged: (callback: (event: MoveEvent) => void) => Listener;
            onMouseMove: (callback: (event: MoveEvent) => void) => Listener;
            onMouseUp: (callback: (event: MoveEvent) => void) => Listener;
            onMouseDown: (callback: (event: ClickEvent) => void) => Listener;
            updateCommentsDrawingMode: (mode: CommentsDrawingModes) => void;
            isInvalidCommentPosition: (lng: number, lat: number) => boolean;
            userPlacedCommentInvalidPosition: () => void;
        },
        private user: { id: string; isMobileUser: boolean },
        private readonly: boolean,
        private project: EngineInterpreter["project"],
        private selectedComment?: SelectedMapComment
    ) {
        const guards = {
            existingObject: () => this.selectedComment != null,
            isCommentSelected: (_, event: HoverOverCommentEvent | ClickMachineEvent) =>
                event.type === "CLICK"
                    ? event.objects?.some((obj) => obj.layerId === commentsLayerId && obj.objectId === this.selectedComment?.commentId)
                    : event.commentId === this.selectedComment?.commentId,
            isReadonly: () => this.readonly,
            canEditComment: () => this.selectedComment?.userId === this.user.id,
            isACommentSelected: () => this.selectedComment != null,
            isOverComment: (_, event: ClickEvent) => event.objects?.some((obj) => obj.layerId === commentsLayerId),
            isInvalidCommentPosition: (_, event: ClickEvent | DragEndEvent) => this.operations.isInvalidCommentPosition(event.lat, event.lng),
        } as const;

        const createComment = (_, event: { lng: number; lat: number }) => {
            const commentPoint = point([event.lng, event.lat]);
            this.selectedComment = { commentId: uuid(), userId: this.user.id, canvasCoordinates: project(commentPoint.geometry.coordinates) };
            this.listeners[CommentDrawingEvent.APPEND].forEach((listener) => listener(commentPoint.geometry, this.selectedComment.commentId));
        };
        const panningHasStarted = () => this.listeners[CommentDrawingEvent.PAN_START].forEach((listener) => listener(this.selectedComment?.commentId));
        // Save the geometry
        const panningHasEnded = () => this.listeners[CommentDrawingEvent.PAN_END].forEach((listener) => listener(this.selectedComment?.commentId));
        const draggingHasStarted = () => this.listeners[CommentDrawingEvent.DRAG_START].forEach((listener) => listener(this.selectedComment?.commentId));

        // Save the geometry
        const draggingHasEnded = (_, event: DragEndEvent | ExitEvent) => {
            if (event.type === "DRAG_END") {
                this.listeners[CommentDrawingEvent.DRAG_END].forEach((listener) =>
                    listener(point([event.lng, event.lat]).geometry, this.selectedComment?.commentId, !this.operations.isInvalidCommentPosition(event.lat, event.lng))
                );
            }
        };

        const selectComment = (_, event: ClickMachineEvent | SelectCommentEvent) => {
            let selectedComment: SelectedMapComment;
            if (event.type === "SELECT_COMMENT") {
                selectedComment = event.comment;
            } else {
                const object = event.objects.find((object) => object.properties.layerid === commentsLayerId);
                // Check the object is a point for type safety
                if (object?.geometry.type !== "Point") return;
                const canvasCoordinates = project(object.geometry.coordinates);
                selectedComment = object == null ? undefined : { commentId: object.objectId, userId: object.properties.userId, canvasCoordinates };
            }
            if (this.selectedComment?.commentId === selectedComment?.commentId) return;
            this.listeners[CommentDrawingEvent.SELECT_COMMENT].forEach((listener) => listener(this.selectedComment?.commentId, selectedComment));
            this.selectedComment = selectedComment;
        };
        const deselectComment = () => {
            if (this.selectedComment == null) return;
            this.listeners[CommentDrawingEvent.SELECT_COMMENT].forEach((listener) => listener(this.selectedComment?.commentId, undefined));
            this.selectedComment = undefined;
        };
        const deleteComment = (_, event: DeleteCommentEvent) => {
            this.listeners[CommentDrawingEvent.DELETE_COMMENT].forEach((listener) => listener(event.commentId));
            this.selectedComment = undefined;
        };
        const compositionHasEnded = (_, event: { type: (DesktopMachineState | MobileMachineState)["eventsCausingActions"]["compositionHasEnded"] }) => {
            // If we are clicking or selecting another coment, keep the machine alive
            this.listeners[CommentDrawingEvent.EXIT_COMPOSITION].forEach((listener) => listener(["CLICK", "SELECT_COMMENT", "CREATE"].includes(event.type)));
        };

        this.machine = user.isMobileUser
            ? interpret(
                  commentsDrawingMachineMobile.withConfig({
                      guards,
                      actions: {
                          createComment,
                          panningHasStarted,
                          panningHasEnded,
                          selectComment,
                          deleteComment,
                          deselectComment,
                          compositionHasEnded,
                      },
                  })
              )
            : interpret(
                  commentsDrawingMachineDesktop.withConfig({
                      guards,
                      actions: {
                          compositionHasEnded,
                          createComment,
                          draggingHasStarted,
                          draggingHasEnded,
                          contentUnderMouseChanged: send((_, event) => {
                              const commentObject = event.objects
                                  ?.filter((object) => object.properties.layerid === commentsLayerId)
                                  .sort((a, b) => b.properties.order - a.properties.order)?.[0];
                              return commentObject ? { type: "HOVER_OVER_COMMENT", commentId: commentObject.properties?.id } : { type: "MOVE_AWAY" };
                          }),
                          selectComment,
                          deleteComment,
                          deselectComment,
                          panningHasEnded,
                          userPlacedCommentInvalidPosition: () => this.operations.userPlacedCommentInvalidPosition(),
                      },
                  })
              );
        this.operations.updateCommentsDrawingMode(this.machine.machine.initial as CommentsDrawingModes);
        this.machine.onTransition(({ value }) => {
            if (this.state !== value) {
                this.state = value as CommentsDrawingModes;
                this.operations.updateCommentsDrawingMode(value as CommentsDrawingModes);
            }
        });

        this.machine.start();

        const dragFunction = () => {
            let dragStarted = false;
            this.mouseDragListener = this.operations.onMouseMove((e) => {
                if (!dragStarted) {
                    this.machine.send({ ...e, type: "DRAG_START" });
                    dragStarted = true;
                } else {
                    this.machine.send({ ...e, type: "MOUSE_MOVE" });
                }
            });

            this.mouseUpListener = this.operations.onMouseUp((event) => {
                this.mouseUpListener?.remove();
                this.mouseUpListener = undefined;
                this.mouseDragListener?.remove();
                // A mouse move event always fires after the mouse up event. So we don't actually want to transition, until we recieve that mouse move event, otherwise we would end up in the default mode
                this.mouseMoveListener = this.operations.onMouseMove(() => {
                    this.machine.send({ ...event, type: "DRAG_END" });
                    this.mouseMoveListener?.remove();
                    this.mouseMoveListener = this.mouseMoveEvent();
                });
            });
        };

        this.mouseDownListener = this.operations.onMouseDown(dragFunction);
        this.mouseMoveListener = this.mouseMoveEvent();
        this.onContentUnderMouseChangedListener = this.operations.onContentUnderMouseChanged((event) => this.machine.send({ ...event, type: "CONTENT_UNDER_MOUSE_CHANGED" }));
        this.clickListener = this.operations.onClick((event) => this.machine.send({ ...event, type: "CLICK" }));
        this.rightClickListener = this.operations.onRightClick((event) => this.machine.send({ ...event, type: "CLICK" }));
        this.panStartListener = this.operations.onPanStart(() => this.machine.send({ type: "PAN_START" }));
        this.panEndListener = this.operations.onPanEnd(() => this.machine.send({ type: "PAN_END" }));
    }

    private mouseMoveEvent = () =>
        this.operations.onMouseMove((event) => {
            if (this.machine?.status === InterpreterStatus.Stopped) {
                this.mouseMoveListener?.remove();
            } else {
                this.machine.send({ ...event, type: "MOUSE_MOVE" });
            }
        });

    public getCursor(layerId: string, commentId: string) {
        if (this.state === "dragging") {
            return MapCursor.COMMENT;
        }
        if (layerId === commentsLayerId) {
            if (this.selectedComment?.commentId === commentId && this.selectedComment?.userId === this.user.id && !this.readonly) {
                return MapCursor.MOVE;
            }
            return MapCursor.POINTING;
        }
        if (this.selectedComment != null || this.readonly) {
            return MapCursor.READ;
        }
        return MapCursor.COMMENT;
    }

    public createCommentExternally(lng: number, lat: number) {
        this.machine.send({ type: "CREATE", lng, lat });
    }

    public cancel() {
        this.machine.send({ type: "CANCEL" });
    }

    public getSelectedComment() {
        return this.selectedComment;
    }

    public selectCommentExternally(comment: SelectedMapComment) {
        this.machine.send({ type: "SELECT_COMMENT", comment });
    }

    public confirmCommentMove() {
        this.machine.send({ type: "CONFIRM_COMMENT_MOVE" });
    }

    public deleteComment(commentId: string) {
        this.machine.send({ type: "DELETE_COMMENT", commentId });
    }

    public on<E extends CommentDrawingEvent>(event: E, callback: CommentDrawingListeners[E][0]) {
        this.listeners[event].push(callback as () => void);
        return this;
    }

    public destroy() {
        this.listeners[CommentDrawingEvent.DESTROY].forEach((listener) => listener(this.selectedComment));
        this.mouseMoveListener?.remove();
        this.onContentUnderMouseChangedListener?.remove();
        this.clickListener?.remove();
        this.rightClickListener?.remove();
        this.panStartListener?.remove();
        this.panEndListener?.remove();
        this.zoomStartListener?.remove();
        this.zoomEndListener?.remove();
        this.mouseDownListener?.remove();
        this.mouseUpListener?.remove();
        this.mouseDragListener?.remove();
        this.machine?.stop();
    }
}
