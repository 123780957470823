import { createMachine } from "xstate";
import GeoJSON from "geojson";
import { ModeOfTransport } from "@iventis/domain-model/model/modeOfTransport";
import { RouteWaypoint } from "@iventis/map-types";
import { MoveEvent } from "../types/internal";

export type RouteControlsMachineEvents =
    | { type: "ENTER_LINE" }
    | { type: "ENTER_WAYPOINT" }
    | { type: "DRAG_START"; payload: { object: GeoJSON.Point | GeoJSON.LineString; position: GeoJSON.Position } }
    | { type: "DRAG_END"; payload: { lng: number; lat: number } }
    | { type: "PLACE"; payload: { lng: number; lat: number } }
    | { type: "MOVE"; payload: MoveEvent }
    | { type: "CONTENT_UNDER_MOUSE_CHANGED"; payload: MoveEvent }
    | { type: "MOVE_AWAY" }
    | { type: "UPDATE_MODE_OF_TRANSPORT"; payload: ModeOfTransport }
    | { type: "UPDATE_WAYPOINTS_EXTERNAL"; payload: RouteWaypoint[] }
    | { type: "FOCUS_WAYPOINT"; payload: { index: number; isNewWaypoint: boolean } };

export type RouteControlsMachineContext = {};

export const routeControlsMachine = createMachine(
    {
        /** @xstate-layout N4IgpgJg5mDOIC5QCUD2BXALmABDAdmAE4CGmqRAxALIDyAagKIDaADALqKgAOqsAlpn6p8XEAA9EARlYBWACwA6ebIDMUgEwB2AJysAHFM2yANCACeiALTz9AXztm0WXAWJkKlAMK0AcgBVGAIB9AFVfABFGZGC6UIBlRmCvAAkAQV8AcUYItk4kEF4BIRExSQQpVUNlfXlVWw15ADYdHS0zSwQbfUVWPrkZLX19XQ0pBycMbDwwQlJyKlCABQi0wNjaKODaADFg-2QM+KXaZH88sSLBYVEC8qbZVkVG1qMh2QUpUwtreS1FVqtfQaNqqLSsFrjRwgZzTNzzTzLVbrADqaQAmicAJIBeLBRgADUCyF8aQAMhcClcSrdQOUpDpFDIdGCWaxVKoms0NB1EBypIpDFJmnotPJtPoHhMYVNXLN3AtKEi1kk0ZjaDj-HjCcTSRSpPkeHxrqU7ogmu0fggmpLlCCtEY6lIWk1pbC5XMPERFBAwAAzEjoAA2mEoQWJwTJOJYHEuxppZUQhieGkM9q+YvkOh5Vo0sn+VX0rA0BmFJfkbtlM09Cx9-sDIbDAWiwTV2IClKNxRuiYQyeeabaGfkWZznRHSgeTVTWeaDIr0Pd1YVFEUAAtUAA3Yi0bdEMn8QhNiNtjUd2NU+M9s0VJqsRmqWQsjl-erfTqqEFMpqqOQ6FRaDa96Vi4y4It6G57ruxAHkecSJMEES0CividoUV6mnS0h3k0gpPhy9RaG+vIVGKAJqJo5ZZnIWhaCBcLyuB65bjue6wWANAMEkaRqmh1LXlhCAsv8JbqLIUj6JyT5GCRJa4cMug6E0ymPPIrC2PRHorhBLFENBRAoiQ5i8IeobwUkSEoXxGG0hI0i1D04kfM0d4KLIeYkbIDzKBC2iAiOwL2IuVbwl6zFQXuhnGagpmcUwwQ8Ri1ndphdkIB8uGppRGgluJQxjog4kaACvmKf+DRBZMoGhbWkGscQUUmfgobhi2Ua+DGhroSltn0hCAr6PhL5ESoJGcqoPnTuoLpfM5mlgWF3BBiQADGh5QI1MXNd4UZeAA0slJq9dh4l4c+hHEVaNq4dmQ7CtNSnzTVq4QKQUBQOtm2xXQTCHQmN5jBJzz5spFpZlyIxjRCyiclmSlFvUfxPYxYWvSQ72fUZTVmbQCRJMsf0CWlEktCV042mMfwgvoJFqY5lT9XUEJinRwXVSjtZoxj+AbVjW2hj4zYhOEUQxOZyTpFkOSE6l5RjF+CgWm0kqDfIXwkT+Twslyat-INOiyA40L4KgvrwAUS7PUQcY9b2VipoKejThCSk-moNNWiMygUe5XmNJJOjIzWL31sGmA20dvayMCiiqE7KgG1yoKyfevSsjokqfoBRaG2zDHBzpEUwYeYAR-9glGHmyizWrxZyPeOgkQyjJFs0aY-nmrp51pTF1XpkV86ZZdE-SXJPMKNHO2otEe+OYyKFOqYjIpBGqEH2mKEtq2Y9FQ+XrbN7MrhvmPNHnK6FIGu2l805aKp2gOrnVX5xvXMfTzX3NcPsuICWwyx4+XQHwxhNEqLTVgBZ9BKXvPyJ8KgjZ2CAA */
        id: "Route generator",
        initial: "default",
        on: {
            MOVE: { actions: "contentUnderMouseChanged" },
            CONTENT_UNDER_MOUSE_CHANGED: { actions: "contentUnderMouseChanged" },
            UPDATE_MODE_OF_TRANSPORT: { actions: "updateModeOfTransport" },
            UPDATE_WAYPOINTS_EXTERNAL: [
                { actions: "updateWaypointsExternal", cond: "areAllWaypointsNonEmpty", target: ".default" },
                { actions: ["clearGeometry", "setActiveIndexToEmptyWaypoint"], target: ".placingWaypoint" },
            ],
        },
        states: {
            default: {
                on: {
                    ENTER_LINE: [
                        {
                            cond: "canAddWaypoint",
                            target: "hoverOverLine",
                        },
                    ],
                    ENTER_WAYPOINT: {
                        target: "hoverOverWaypoint",
                    },
                },

                entry: "enterDefault",
            },

            hoverOverLine: {
                on: {
                    ENTER_WAYPOINT: {
                        target: "hoverOverWaypoint",
                    },
                    DRAG_START: {
                        target: "draggingWaypoint",
                        actions: ["addWaypoint"],
                    },
                    MOVE_AWAY: {
                        target: "default",
                    },
                },

                entry: "enterLine",
            },

            hoverOverWaypoint: {
                on: {
                    DRAG_START: "draggingWaypoint",
                    MOVE_AWAY: {
                        target: "default",
                    },
                    ENTER_LINE: [
                        {
                            cond: "canAddWaypoint",
                            target: "hoverOverLine",
                        },
                    ],
                },

                entry: "enterWaypoint",
            },

            placingWaypoint: {
                on: {
                    PLACE: [
                        {
                            cond: "areTwoOrMoreWaypointsEmpty",
                            actions: "placeWaypointAndBeginPlacingNext",
                        },
                        {
                            target: "hoverOverWaypoint",
                            actions: "finishDragging",
                        },
                    ],
                },

                entry: "enterPlacingMode",
            },

            draggingWaypoint: {
                on: {
                    MOVE: { actions: "dragWaypoint" },
                    DRAG_END: { target: "hoverOverWaypoint", actions: "finishDragging" },
                    // Do not run this event while dragging
                    CONTENT_UNDER_MOUSE_CHANGED: {},
                },

                entry: ["enterWaypoint", "startDragging"],
            },
        },
        schema: {
            events: {} as RouteControlsMachineEvents,
            context: {} as RouteControlsMachineContext,
        },
        // eslint-disable-next-line no-undef
        tsTypes: {} as import("./route-controls-machine.typegen").Typegen0,
        predictableActionArguments: true,
        preserveActionOrder: true,
    },
    {
        guards: {},
        actions: {},
        services: {},
        delays: {},
    }
);
