import { Listener } from "@iventis/types";
import { ModeOfTransport } from "@iventis/domain-model/model/modeOfTransport";
import { coordinatesToString } from "@iventis/utilities";
import { DebouncedFunc, debounce } from "lodash";
import GeoJSON from "geojson";
import { interpret, Interpreter } from "xstate";
import { point } from "@turf/helpers";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import { send } from "xstate/lib/actions";
import { MapObjectProperties, RouteWaypoint } from "@iventis/map-types";
import { waypointLayerId } from "../bridge/constants";
import { MoveEvent, RouteApiFunctions } from "../types/internal";
import { getIndexClosestCoordinate } from "./geojson-helpers";
import { routeControlsMachine, RouteControlsMachineContext, RouteControlsMachineEvents } from "./route-controls-machine";
import { createBasicRouteWaypoint, generateRouteFeatureAndWaypoints } from "./route-helpers";

export enum RouteControlsEvent {
    START_DRAG = "START_DRAG",
    DRAG = "DRAG",
    RELEASE = "RELEASE",
    DESTROY = "DESTROY",
    ENTER_WAYPOINT = "ENTER_WAYPOINT",
    ENTER_LINE = "ENTER_LINE",
    LEAVE = "LEAVE",
    REGISTER_WAYPOINTS = "REGISTER_WAYPOINTS",
    PLACING_WAYPOINT = "PLACING_WAYPOINT",
    CLEAR_GEOMETRY = "CLEAR_GEOMETRY",
}

export interface RouteControlListeners {
    [RouteControlsEvent.START_DRAG]: ((point: GeoJSON.Point) => void)[];
    [RouteControlsEvent.ENTER_WAYPOINT]: (() => void)[];
    [RouteControlsEvent.ENTER_LINE]: (() => void)[];
    [RouteControlsEvent.PLACING_WAYPOINT]: (() => void)[];
    [RouteControlsEvent.CLEAR_GEOMETRY]: (() => void)[];
    [RouteControlsEvent.LEAVE]: (() => void)[];
    [RouteControlsEvent.DRAG]: ((transformation: GeoJSON.Feature<GeoJSON.LineString>) => void)[];
    [RouteControlsEvent.RELEASE]: ((payload: { feature: GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties>; waypoints: RouteWaypoint[] }) => void)[];
    [RouteControlsEvent.DESTROY]: (() => void)[];
    [RouteControlsEvent.REGISTER_WAYPOINTS]: ((waypoints: RouteWaypoint[]) => void)[];
}

export class RouteControls {
    private listeners: RouteControlListeners = {
        [RouteControlsEvent.START_DRAG]: [],
        [RouteControlsEvent.ENTER_WAYPOINT]: [],
        [RouteControlsEvent.ENTER_LINE]: [],
        [RouteControlsEvent.PLACING_WAYPOINT]: [],
        [RouteControlsEvent.LEAVE]: [],
        [RouteControlsEvent.DRAG]: [],
        [RouteControlsEvent.RELEASE]: [],
        [RouteControlsEvent.DESTROY]: [],
        [RouteControlsEvent.REGISTER_WAYPOINTS]: [],
        [RouteControlsEvent.CLEAR_GEOMETRY]: [],
    };

    private mouseDownListener: Listener;

    private mouseDragListener: Listener;

    private mouseUpListener: Listener;

    private mouseMoveListener: Listener;

    private clickListener: Listener;

    private rightClickListener: Listener;

    private onContentUnderMouseChangedListener: Listener;

    private indexDragging: number;

    private routeFinderDebounce: DebouncedFunc<(position: { lng: number; lat: number }) => Promise<void>>;

    private latestTransformation: GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties>;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private machine: Interpreter<RouteControlsMachineContext, any, RouteControlsMachineEvents, any, any>;

    constructor(
        object: GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties> | undefined,
        private waypoints: RouteWaypoint[],
        private maxWaypoints: number,
        public operations: {
            onMouseMove: (callback: (e: MoveEvent) => void, radius?: number) => Listener;
            onMouseDown: (callback: (point: GeoJSON.Point | GeoJSON.LineString, position: GeoJSON.Position) => void) => Listener;
            onMouseUp: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onClick: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onRightClick: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onContentUnderMouseChanged: (callback: (e: MoveEvent) => void) => Listener;
            setWaypointGeometry: (waypoints: RouteWaypoint[]) => void;
            routeFinder: RouteApiFunctions["routeFinder"];
            getDurationProperty: () => Promise<string>;
            zoomToRoute: (transformRequest: GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties>) => void;
        }
    ) {
        this.latestTransformation = JSON.parse(JSON.stringify(object));
        this.machine = interpret(
            routeControlsMachine.withConfig({
                guards: {
                    canAddWaypoint: () => this.waypoints.length < this.maxWaypoints,
                    areAllWaypointsNonEmpty: (_, event) => event.payload.every((waypoint) => !isRouteWaypointEmpty(waypoint)),
                    areTwoOrMoreWaypointsEmpty: () => this.waypoints.filter((waypoint) => isRouteWaypointEmpty(waypoint)).length > 1,
                },
                actions: {
                    addWaypoint: (_, event) => {
                        const nearestCoordinate = nearestPointOnLine(this.latestTransformation, event.payload.position).geometry.coordinates;
                        const closestCoordIndex = getIndexClosestCoordinate(this.latestTransformation.geometry.coordinates, nearestCoordinate, (coord) => coord);
                        this.waypoints = [
                            ...this.waypoints.filter(
                                (waypoint) => getIndexClosestCoordinate(this.latestTransformation.geometry.coordinates, waypoint.coordinates, (coord) => coord) < closestCoordIndex
                            ),
                            createBasicRouteWaypoint(event.payload.position),
                            ...this.waypoints.filter(
                                (waypoint) => getIndexClosestCoordinate(this.latestTransformation.geometry.coordinates, waypoint.coordinates, (coord) => coord) > closestCoordIndex
                            ),
                        ];
                    },
                    clearGeometry: (_, event) => {
                        this.waypoints = event.payload;
                        this.operations.setWaypointGeometry(event.payload);
                        // If the empty waypoint is the first or last waypoint and there is more than one remaining waypoint, we need to draw the line between the existing waypoints
                        const indexOfEmptyWaypoint = event.payload.findIndex((waypoint) => isRouteWaypointEmpty(waypoint));
                        if ((indexOfEmptyWaypoint === 0 || indexOfEmptyWaypoint === event.payload.length - 1) && event.payload.filter((w) => w.coordinates).length > 1) {
                            this.getTransformedRoute().then((transformation) => {
                                this.listeners[RouteControlsEvent.DRAG].forEach((listener) => listener(transformation.feature));
                            });
                        } else {
                            // Else we just clear the geometry
                            this.listeners[RouteControlsEvent.CLEAR_GEOMETRY].forEach((listener) => listener());
                        }
                    },
                    enterPlacingMode: () => {
                        this.mouseDownListener?.remove();
                        this.listeners[RouteControlsEvent.PLACING_WAYPOINT].forEach((listener) => listener());
                    },
                    placeWaypointAndBeginPlacingNext: (_, event) => {
                        this.waypoints = [createBasicRouteWaypoint([event.payload.lng, event.payload.lat]), { name: "", coordinates: undefined }];
                        this.operations.setWaypointGeometry(this.waypoints);
                        this.listeners[RouteControlsEvent.REGISTER_WAYPOINTS].forEach((listener) => listener(this.waypoints));
                        this.indexDragging = 1;
                    },
                    setActiveIndexToEmptyWaypoint: (_, event) => {
                        const index = event.payload.findIndex((waypoint) => isRouteWaypointEmpty(waypoint));
                        this.indexDragging = index;
                    },
                    enterDefault: () => {
                        this.listeners[RouteControlsEvent.LEAVE].forEach((listener) => listener());
                    },
                    enterLine: () => {
                        this.listeners[RouteControlsEvent.ENTER_LINE].forEach((listener) => listener());
                    },
                    enterWaypoint: () => {
                        this.listeners[RouteControlsEvent.ENTER_WAYPOINT].forEach((listener) => listener());
                    },
                    dragWaypoint: (_, { payload: position }) => {
                        this.routeFinderDebounce.cancel();
                        this.routeFinderDebounce(position);

                        this.operations.setWaypointGeometry(
                            this.waypoints.map((waypoint, index) => (index === this.indexDragging ? createBasicRouteWaypoint([position.lng, position.lat]) : waypoint))
                        );
                    },
                    finishDragging: (_, { payload: position }) => {
                        this.addMouseDownListener();
                        this.routeFinderDebounce.cancel();
                        this.getTransformedRoute(position).then((transformation) => {
                            this.latestTransformation = transformation.feature;
                            this.waypoints = transformation.waypoints;
                            this.listeners[RouteControlsEvent.REGISTER_WAYPOINTS].forEach((listener) => listener(transformation.waypoints));
                            this.indexDragging = undefined;
                            this.listeners[RouteControlsEvent.RELEASE].forEach((listener) => listener(transformation));
                        });
                    },
                    startDragging: (_, event) => {
                        this.mouseDownListener.remove();
                        const startingPoint = point(event.payload.position).geometry;
                        this.indexDragging = getIndexClosestCoordinate<Pick<RouteWaypoint, "coordinates">>(this.waypoints, startingPoint, (w) => w.coordinates);
                        this.listeners[RouteControlsEvent.START_DRAG].forEach((listener) => listener(startingPoint));
                    },
                    contentUnderMouseChanged: send((_, { payload: event }) => {
                        const lineObject = event.objects.find(
                            (object) =>
                                object.properties.layerid === this.latestTransformation.properties.layerid && object.properties.id === this.latestTransformation.properties.id
                        ) as GeoJSON.Feature<GeoJSON.LineString>;
                        const waypointObject = event.objects.find((object) => object.properties.layerid === waypointLayerId) as GeoJSON.Feature<GeoJSON.Point>;
                        const eventType: RouteControlsMachineEvents["type"] = waypointObject ? "ENTER_WAYPOINT" : lineObject ? "ENTER_LINE" : "MOVE_AWAY";
                        return { type: eventType };
                    }),
                    updateModeOfTransport: (_, { payload: modeOfTransport }) => {
                        this.latestTransformation.properties.modeOfTransport = modeOfTransport;
                        this.routeFinderDebounce.cancel();
                        if (this.waypoints.filter((waypoint) => !isRouteWaypointEmpty(waypoint)).length > 1) {
                            this.getTransformedRoute().then((transformation) => {
                                this.latestTransformation = transformation.feature;
                                this.waypoints = transformation.waypoints;
                                // We call RELEASE here because we've transformed the route
                                this.listeners[RouteControlsEvent.RELEASE].forEach((listener) => listener(transformation));
                            });
                        }
                    },
                    updateWaypointsExternal: (_, { payload: waypoints }) => {
                        this.waypoints = waypoints;
                        this.operations.setWaypointGeometry(this.waypoints);
                        this.getTransformedRoute().then((transformation) => {
                            this.latestTransformation = transformation.feature;
                            this.waypoints = transformation.waypoints;
                            // We call RELEASE here because we've transformed the route
                            this.listeners[RouteControlsEvent.RELEASE].forEach((listener) => listener(transformation));
                            this.operations.zoomToRoute(transformation.feature);
                        });
                    },
                },
            })
        );
        this.machine.start();

        // If there are no waypoints, we start with an empty waypoint which will transition us into a placingWaypoint state
        if (this.waypoints == null || this.waypoints.length === 0) {
            this.machine.send({
                type: "UPDATE_WAYPOINTS_EXTERNAL",
                payload: [
                    { name: "", coordinates: undefined },
                    { name: "", coordinates: undefined },
                ],
            });
        }

        if (this.waypoints?.length > 0) {
            this.operations.setWaypointGeometry(this.waypoints);
        }

        this.routeFinderDebounce = debounce(async (position) => {
            const transformedRoute = await this.getTransformedRoute(position);
            this.latestTransformation = transformedRoute.feature;
            this.waypoints = transformedRoute.waypoints;
            this.listeners[RouteControlsEvent.DRAG].forEach((listener) => listener(transformedRoute.feature));
        }, 70);
        this.mouseMoveListener = this.operations.onMouseMove((event) => this.machine.send({ type: "MOVE", payload: event }));
        this.onContentUnderMouseChangedListener = this.operations.onContentUnderMouseChanged((event) => this.machine.send({ type: "CONTENT_UNDER_MOUSE_CHANGED", payload: event }));
        this.addMouseDownListener();
        this.mouseUpListener = this.operations.onMouseUp((event) => this.machine.send({ type: "DRAG_END", payload: event }));
        this.clickListener = this.operations.onClick((event) => this.machine.send({ type: "PLACE", payload: event }));
        this.rightClickListener = this.operations.onRightClick((event) => this.machine.send({ type: "PLACE", payload: event }));
    }

    private addMouseDownListener() {
        this.mouseDownListener = this.operations.onMouseDown((object, position) => this.machine.send({ type: "DRAG_START", payload: { object, position } }));
    }

    async getTransformedRoute(newCoordinate?: { lng: number; lat: number }) {
        // Only update the waypoint if a coordinate has been provided
        if (newCoordinate) {
            const position = [newCoordinate.lng, newCoordinate.lat];
            this.waypoints[this.indexDragging].coordinates = position;
            this.operations.setWaypointGeometry(this.waypoints);
            // Send the waypoints as they are first, with the coordinates as the name
            this.waypoints[this.indexDragging].name = coordinatesToString(position);
            this.listeners[RouteControlsEvent.REGISTER_WAYPOINTS].forEach((listener) => listener(this.waypoints));
        }

        const validWaypoints = this.waypoints.filter((w) => w.coordinates);
        if (validWaypoints.length < 2) {
            return { feature: this.latestTransformation, waypoints: this.waypoints };
        }

        // Apply the duration and the geometry to the latest transformation
        const durationProperty = await this.operations.getDurationProperty();

        const result = await generateRouteFeatureAndWaypoints(async () => this.operations.routeFinder(validWaypoints, this.latestTransformation.properties.modeOfTransport), {
            preGeneratedFeature: this.latestTransformation,
            preGeneratedWaypoints: this.waypoints,
            durationProperty,
        });

        return result;
    }

    removeWaypoints() {
        this.mouseDownListener.remove();
    }

    updateModeOfTransport(modeOfTransport: ModeOfTransport) {
        this.machine.send({ type: "UPDATE_MODE_OF_TRANSPORT", payload: modeOfTransport });
    }

    updateWaypointsExternal(waypoints: RouteWaypoint[]) {
        this.machine.send({ type: "UPDATE_WAYPOINTS_EXTERNAL", payload: waypoints });
    }

    /** Gets the current state of the route controls machine */
    getcurrentState() {
        return this.machine.getSnapshot().value as string;
    }

    public on<E extends RouteControlsEvent>(event: E, callback: RouteControlListeners[E][0]) {
        this.listeners[event].push(callback as () => void);
        return this;
    }

    destroy() {
        this.operations.setWaypointGeometry([]);
        this.mouseMoveListener?.remove();
        this.mouseUpListener?.remove();
        this.mouseDownListener?.remove();
        this.mouseDragListener?.remove();
        this.clickListener?.remove();
        this.rightClickListener?.remove();
        this.onContentUnderMouseChangedListener?.remove();
        this.listeners[RouteControlsEvent.DESTROY].forEach((listener) => listener());
        this.machine.stop();
    }
}

export const isRouteWaypointEmpty = (waypoint: RouteWaypoint) => waypoint.name == null || waypoint.name.length === 0 || waypoint.coordinates == null;
