import React, { FunctionComponent, useEffect, useMemo, useRef } from "react";
import { AreaStyle } from "@iventis/domain-model/model/areaStyle";
import { styled } from "@iventis/styles";
import { LoadingComponent } from "@iventis/components";
import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import { PointStyle } from "@iventis/domain-model/model/pointStyle";
import { LineStyle } from "@iventis/domain-model/model/lineStyle";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { ModelStyle } from "@iventis/domain-model/model/modelStyle";
import { LineModelStyle } from "@iventis/domain-model/model/lineModelStyle";
import { LayerStyle } from "@iventis/domain-model/model/layerStyle";
import { useQuery } from "@tanstack/react-query";
import { Asset } from "@iventis/domain-model/model/asset";
import { MapObjectProperties } from "@iventis/map-types";
import { extractZoomableValueFromStyleValue } from "@iventis/map-engine/src/utilities/style-helpers";
import { getStaticStyleValue, getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { AreaIcon, IconIcon, IconWithAttributeBasedColourStyling, LineIcon, PointIcon } from "@iventis/layer-styles/src/assets/svg-icons";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";

const AreaThumbnail: FunctionComponent<{ areaStyle: AreaStyle; styleSelector: MapObjectProperties | string; onRendered?: () => void }> = ({
    areaStyle,
    styleSelector,
    onRendered,
}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (onRendered) {
            onRendered();
        }
    }, [ref.current]);
    return (
        <AreaIcon
            id={typeof styleSelector === "string" && styleSelector}
            ref={ref}
            className="style-preview-icon"
            fill={extractZoomableValueFromStyleValue(areaStyle.colour, styleSelector).staticValue}
            stroke={areaStyle.outline ? extractZoomableValueFromStyleValue(areaStyle.outlineColour, styleSelector).staticValue : null}
            strokeWidth={areaStyle.outline ? extractZoomableValueFromStyleValue(areaStyle.outlineWidth, styleSelector).staticValue : 0}
        />
    );
};

const LineThumbnail: FunctionComponent<{ lineStyle: LineStyle; styleSelector: MapObjectProperties | string; onRendered?: () => void }> = ({
    lineStyle,
    styleSelector,
    onRendered,
}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (onRendered) {
            onRendered();
        }
    }, [ref.current]);
    return (
        <LineIcon
            id={typeof styleSelector === "string" && styleSelector}
            className="style-preview-icon"
            stroke={extractZoomableValueFromStyleValue(lineStyle.colour, styleSelector).staticValue}
            strokeWidth={extractZoomableValueFromStyleValue(lineStyle.width, styleSelector).staticValue}
            displayArrows={extractZoomableValueFromStyleValue(lineStyle.arrows, styleSelector).staticValue}
            arrowColour={extractZoomableValueFromStyleValue(lineStyle.arrowColour, styleSelector).staticValue}
            displayOutline={extractZoomableValueFromStyleValue(lineStyle.outline, styleSelector).staticValue}
            outlineColour={extractZoomableValueFromStyleValue(lineStyle.outlineColour, styleSelector).staticValue}
            outlineWidth={extractZoomableValueFromStyleValue(lineStyle.outlineWidth, styleSelector).staticValue}
        />
    );
};

const PointThumbnail: FunctionComponent<{ pointStyle: PointStyle; styleSelector: MapObjectProperties | string; onRendered?: () => void }> = ({
    pointStyle,
    styleSelector,
    onRendered,
}) => {
    const ref = useRef(null);
    useEffect(() => {
        if (onRendered) {
            onRendered();
        }
    }, [ref.current]);
    return (
        <PointIcon
            id={typeof styleSelector === "string" && styleSelector}
            className="style-preview-icon"
            fill={extractZoomableValueFromStyleValue(pointStyle.colour, styleSelector).staticValue}
            stroke={pointStyle.outline ? extractZoomableValueFromStyleValue(pointStyle.outlineColour, styleSelector).staticValue : null}
            strokeWidth={pointStyle.outline ? extractZoomableValueFromStyleValue(pointStyle.outlineWidth, styleSelector).staticValue : 0}
        />
    );
};

const IconThumbnail: FunctionComponent<{
    iconStyle: IconStyle;
    styleSelector: MapObjectProperties | string;
    previewIconUrlGetter: (id: string) => Promise<string>;
    getAsset: (id: string) => Promise<Asset>;
    onRendered?: () => void;
}> = ({ iconStyle, styleSelector, previewIconUrlGetter, getAsset, onRendered }) => {
    const iconId = useMemo(() => extractZoomableValueFromStyleValue(iconStyle.iconImage, styleSelector).staticValue, [iconStyle?.iconImage, styleSelector]);

    const { data, isLoading } = useQuery([`sidebar-${iconId}`], () => previewIconUrlGetter(iconId));

    const asset = useQuery([`asset-${iconId}`], () => getAsset(iconId));

    useEffect(() => {
        if (onRendered && !isLoading) {
            onRendered();
        }
    }, [isLoading]);

    if (isLoading || data == null) {
        return (
            <StyledPreviewContainer className="style-preview-icon">
                <LoadingComponent size={20} />
            </StyledPreviewContainer>
        );
    }

    const isCustomColour = getStaticStyleValue(iconStyle.customColour) === true && asset?.data?.metaData?.sdf;

    return isCustomColour ? (
        <IconWithAttributeBasedColourStyling styleSelector={styleSelector} colour={iconStyle.colour} className="style-preview-icon" assetUrl={data} />
    ) : (
        <IconIcon id={typeof styleSelector === "string" && styleSelector} className="style-preview-icon" assetUrl={data} />
    );
};

const ModelThumbnail: FunctionComponent<{
    modelStyle: LineModelStyle | ModelStyle;
    styleSelector: MapObjectProperties | string;
    getModelThumbnail: (id: string) => Promise<string>;
    onRendered?: () => void;
}> = ({ modelStyle, styleSelector, getModelThumbnail, onRendered }) => {
    // Get model Id from either object properties or use default static value
    const modelId = useMemo(() => {
        const style = modelStyle;

        if (style.model.extractionMethod === StyleValueExtractionMethod.Mapped) {
            const listValue = typeof styleSelector === "string" ? styleSelector : (styleSelector[style.model.dataFieldId] as string);
            // Get mapped value or use default value
            return style.model.mappedValues[listValue]?.staticValue ?? getStaticStyleValueFromMapped(style.model);
        }

        return getStaticStyleValue(style.model);
    }, [modelStyle, styleSelector]);

    // Get model thumbnail
    const { data: thumbnailUrl, isLoading } = useQuery({ queryKey: [`thumbnail-${modelId}`], queryFn: () => getModelThumbnail(modelId), refetchOnMount: false });

    useEffect(() => {
        if (onRendered && !isLoading) {
            onRendered();
        }
    }, [isLoading]);

    if (isLoading || thumbnailUrl == null) {
        return (
            <StyledPreviewContainer className="style-preview-icon">
                <LoadingComponent size={20} />
            </StyledPreviewContainer>
        );
    }
    return <IconIcon id={typeof styleSelector === "string" && styleSelector} className="style-preview-icon" assetUrl={thumbnailUrl} />;
};

const StyledPreviewContainer = styled.div`
    svg {
        height: 100%;
        width: auto;
    }

    height: 100%;
`;

export const LayerIconGenerated: React.FC<{
    style: LayerStyle;
    styleSelector: MapObjectProperties | string;
    previewIconUrlGetter: (id: string) => Promise<string>;
    modelThumbnailAssetId?: string;
    getAsset: (id: string) => Promise<Asset>;
    getModelThumbnail: (id: string) => Promise<string>;
    onRendered?: () => void;
}> = ({ style, styleSelector, previewIconUrlGetter, getAsset, getModelThumbnail, onRendered }) => {
    const thumbnails = {
        [StyleType.Area]: <AreaThumbnail areaStyle={style as AreaStyle} styleSelector={styleSelector} onRendered={onRendered} />,
        [StyleType.Line]: <LineThumbnail lineStyle={style as LineStyle} styleSelector={styleSelector} onRendered={onRendered} />,
        [StyleType.Point]: <PointThumbnail pointStyle={style as PointStyle} styleSelector={styleSelector} onRendered={onRendered} />,
        [StyleType.Icon]: (
            <IconThumbnail iconStyle={style as IconStyle} styleSelector={styleSelector} previewIconUrlGetter={previewIconUrlGetter} getAsset={getAsset} onRendered={onRendered} />
        ),
        [StyleType.LineModel]: <ModelThumbnail modelStyle={style as LineModelStyle} styleSelector={styleSelector} getModelThumbnail={getModelThumbnail} onRendered={onRendered} />,
        [StyleType.Model]: <ModelThumbnail modelStyle={style as ModelStyle} styleSelector={styleSelector} getModelThumbnail={getModelThumbnail} onRendered={onRendered} />,
    };

    return thumbnails[style.styleType];
};
