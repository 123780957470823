import { IconStyle } from "@iventis/domain-model/model/iconStyle";
import React, { FunctionComponent, useMemo } from "react";
import { hexToFeColorMatrix } from "@iventis/utilities";
import { MapObjectProperties } from "@iventis/map-types";

export const AreaIcon: FunctionComponent<React.SVGProps<SVGPolygonElement>> = ({ id, fill, stroke, strokeWidth, className }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <polygon points="20,100 100,180 180,100 100,20" fill={fill} stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
);

interface LineIconProps extends React.SVGProps<SVGLineElement> {
    displayArrows: boolean;
    arrowColour?: string;
    displayOutline: boolean;
    outlineWidth?: string | number;
    outlineColour?: string;
}

export const LineIcon: FunctionComponent<LineIconProps> = ({ id, stroke, strokeWidth, displayArrows, arrowColour, displayOutline, outlineWidth, outlineColour, className }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        {displayOutline && <line x1="10" y1="100" x2="190" y2="100" stroke={outlineColour} strokeWidth={(outlineWidth as number) + (strokeWidth as number) * 3} />}
        <line x1="20" y1="100" x2="180" y2="100" stroke={stroke} strokeWidth={(strokeWidth as number) * 3} />
        {displayArrows && <polygon points="50,40 150,100 50,160" fill={arrowColour} />}
    </svg>
);

export const PointIcon: FunctionComponent<React.SVGProps<SVGCircleElement>> = ({ id, fill, stroke, strokeWidth, className }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle cx="100" cy="100" r="80" fill={fill} stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
);

export const IconIcon: FunctionComponent<{ assetUrl: string; id: string; className?: string }> = ({ id, assetUrl, className }) => (
    <svg id={id} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <image href={assetUrl} height="200" width="200" />
    </svg>
);

/** Colours an image with the colour the map object is in the map */
export const IconWithAttributeBasedColourStyling: FunctionComponent<{
    assetUrl: string;
    className?: string;
    styleSelector: MapObjectProperties | string;
    colour: IconStyle["colour"];
}> = ({ assetUrl, className, styleSelector, colour }) => {
    const listItemId = useMemo(() => {
        const { dataFieldId } = colour;
        return typeof styleSelector === "string" ? styleSelector : (styleSelector[dataFieldId] as string);
    }, [colour, styleSelector]);

    const matrixValue = useMemo(() => {
        const colourValue = colour.mappedValues?.[listItemId]?.staticValue ?? colour.staticValue.staticValue;
        return hexToFeColorMatrix(colourValue);
    }, [colour, styleSelector]);

    return (
        <svg id={listItemId} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <filter id={`img-filter-${listItemId}`}>
                <feColorMatrix in="SourceGraphic" type="matrix" values={matrixValue} />
            </filter>
            <image filter={`url(#img-filter-${listItemId})`} href={assetUrl} height="200" width="200" />
        </svg>
    );
};
