import { Feature, Polygon } from "geojson";
import mask from "@turf/mask";
import booleanOverlap from "@turf/boolean-overlap";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import booleanWithin from "@turf/boolean-within";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { Status } from "@iventis/domain-model/model/status";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import { BehaviorSubject } from "rxjs";
import { DomainLayer } from "../../state/map.state.types";
import { LayerStorageScope, MapboxEngineData, MapState } from "../../types/store-schema";
import { ViewByStatus } from "../../types/layer-view-by";
import { defaultAreaStyle } from "../../utilities/default-style-values";

export abstract class MaskLayer {
    protected source: Feature<Polygon>;

    protected readonly layerId = "mask-layer";

    protected readonly sourceId = this.layerId;

    protected readonly state: BehaviorSubject<MapState<MapboxEngineData>>;

    protected readonly maskLayer: DomainLayer = {
        id: this.layerId,
        status: Status.Active,
        mapId: "",
        visible: true,
        imported: false,
        name: "mask layer",
        styleType: StyleType.Area,
        areaStyle: {
            ...defaultAreaStyle,
            opacity: createStaticStyleValue(0.8),
            colour: createStaticStyleValue("#ffffff"),
        },
        lineStyle: undefined,
        pointStyle: undefined,
        iconStyle: undefined,
        modelStyle: undefined,
        lineModelStyle: undefined,
        previewIconUrl: "",
        dataFields: [],
        tooltipDataFieldIds: [],
        createdByUserId: "",
        createdByUserName: "",
        createdAt: undefined,
        lastUpdatedByUserId: "",
        lastUpdatedByUserName: "",
        lastUpdatedAt: undefined,
        viewBy: ViewByStatus.OBJECT,
        source: this.layerId,
        stamp: this.sourceId,
        selected: false,
        storageScope: LayerStorageScope.LocalOnly,
        remote: false,
    };

    constructor(polygons: Polygon[], state: BehaviorSubject<MapState<MapboxEngineData>>) {
        if (polygons?.length > 0) {
            const features: Feature<Polygon>[] = polygons.map((polygon) => ({ type: "Feature", geometry: polygon, properties: {} }));
            this.source = mask({ type: "FeatureCollection", features });
        }
        this.state = state;
    }

    protected abstract addLayer(): void;

    protected abstract removeLayer(): void;

    protected abstract addSource(): void;

    protected abstract removeSource(): void;

    protected abstract onMouseEvents(): void;

    public updateSource(updatedPolygon: Polygon): void {
        this.source = mask(updatedPolygon);
    }

    public areObjectsOverlappingMaskLayer(objects: Feature[]) {
        return objects.some((object) => {
            // Point geometries can't overlap, can only be within
            if (object.geometry.type === "Point") {
                return booleanPointInPolygon(object.geometry.coordinates, this.source);
            }
            return booleanOverlap(object, this.source) || booleanWithin(object, this.source);
        });
    }

    public abstract remove(): void;
}
