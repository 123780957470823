import GeoJSON from "geojson";
import { AnySupportedGeometry, MapObjectProperties } from "@iventis/map-types";
import { Listener } from "../types/internal";
import { addCoordinate } from "./geojson-helpers";

export enum CompositionEvent {
    MOVE = "MOVE",
    APPEND = "APPEND",
    FINISH = "FINISH",
}

interface ListenerPattern {
    [CompositionEvent.MOVE]: ((feature: GeoJSON.Feature, moveEvent: { lng: number; lat: number }) => void)[];
    [CompositionEvent.APPEND]: ((feature: GeoJSON.Feature) => void)[];
    [CompositionEvent.FINISH]: (() => void)[];
}

export class Composition {
    private listeners: ListenerPattern = {
        [CompositionEvent.MOVE]: [],
        [CompositionEvent.APPEND]: [],
        [CompositionEvent.FINISH]: [],
    };

    private mouseMoveListener: Listener;

    private mouseClickListener: Listener;

    private doubleClickListener: Listener;

    private enterKeyListener: Listener;

    private rightClickListener: Listener;

    private destroyed: boolean;

    private coordLastClick: { lng: number; lat: number } = { lng: NaN, lat: NaN };

    constructor(
        private object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>,
        private operations: {
            onEnterKeyPress(callback: (e: KeyboardEvent) => void): Listener;
            onMouseMove: (callback: (e: { lng: number; lat: number }) => void, radius?: number) => Listener;
            onClick: (callback: (e: { lng: number; lat: number }) => void) => Listener;
            onDoubleClick: (callback: () => void) => Listener;
            onRightClick: (callback: (e: { lng: number; lat: number }) => void) => Listener;
        }
    ) {
        this.mouseMoveListener = this.operations.onMouseMove((position) => {
            if (this.destroyed) {
                // These events might come in after the class is destroyed. Do nothing if they do.
                return;
            }
            this.listeners.MOVE.forEach((listener) => listener(this.getTransformation(position.lng, position.lat), position));
        });
        this.mouseClickListener = this.operations.onClick((position) => {
            if (this.destroyed) {
                return;
            }
            // If second click is in the same place, do not perform append
            if (this.coordLastClick.lng === position.lng && this.coordLastClick.lat === position.lat) {
                return;
            }
            this.coordLastClick = position;

            this.listeners.APPEND.forEach((listener) => listener(this.getTransformation(position.lng, position.lat)));

            if (this.object.geometry.type === "Point") {
                // Points should finish drawing after first click
                this.listeners.FINISH.forEach((listener) => listener());
            }
        });
        this.doubleClickListener = this.operations.onDoubleClick(() => {
            if (this.destroyed) {
                return;
            }
            this.listeners.FINISH.forEach((listener) => listener());
        });

        this.enterKeyListener = this.operations.onEnterKeyPress((event) => {
            if (this.destroyed) {
                return;
            }
            if (event.key !== "Enter") return;
            this.listeners.FINISH.forEach((listener) => listener());
        });

        this.rightClickListener = this.operations.onRightClick(() => {
            if (this.destroyed) {
                return;
            }
            this.listeners.FINISH.forEach((listener) => listener());
        });
    }

    setObject(object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>) {
        this.object = object;
    }

    getTransformation(lng: number, lat: number) {
        const transformedFeature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> = {
            ...this.object,
            geometry: addCoordinate(this.object.geometry, [lng, lat]),
        };
        return transformedFeature;
    }

    public on<E extends CompositionEvent>(event: E, callback: ListenerPattern[keyof ListenerPattern][0]) {
        this.listeners[event].push(callback as () => void);
        return this;
    }

    public destroy() {
        this.mouseMoveListener.remove();
        this.mouseClickListener.remove();
        this.doubleClickListener.remove();
        this.enterKeyListener.remove();
        this.rightClickListener.remove();
        this.destroyed = true;
    }
}
