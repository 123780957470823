/* eslint-disable no-param-reassign */
import { AreaDimension } from "@iventis/domain-model/model/areaDimension";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { getStaticStyleValue } from "@iventis/layer-style-helpers";
import { MapModuleLayer } from "../../../types/store-schema";
import { ExtendedStyleType, LayerMapOrderUpdate, StyleTypeOrder, styleTypeOrder } from "./layer-ordering-types-and-constants";
import { DomainLayer } from "../../../state/map.state.types";

/** Orders Iventis domain layers */
export function orderDomainLayers(layers: MapModuleLayer[]): MapModuleLayer[] {
    const layersWithNoMapOrderValue: MapModuleLayer[] = [];
    const layersWithPositiveMapOrderValue: MapModuleLayer[] = [];
    const layersWithNegativeMapOrderValue: MapModuleLayer[] = [];

    layers.forEach((layer) => {
        switch (true) {
            case getLayerMapOrderValue(layer) > 0:
                layersWithPositiveMapOrderValue.push(layer);
                break;
            case getLayerMapOrderValue(layer) < 0:
                layersWithNegativeMapOrderValue.push(layer);
                break;
            default:
                layersWithNoMapOrderValue.push(layer);
        }
    });

    const sortedLayersWithNoMapOrderValue = layersWithNoMapOrderValue.reduce<MapModuleLayer[]>((orderedLayers, layer) => {
        // Area layers and 3D area fills are ordered differently, therefore give each one a StyleType value
        const layerStyleType = convertStyleTypeToStyleTypeOrder(layer);
        // Get all the types which are above the layer type being added
        const orderIndex = styleTypeOrder.findIndex((type) => type === layerStyleType);
        const typesAboveLayer = styleTypeOrder.slice(orderIndex + 1);

        // Get the index of where one of the above types is
        const sliceIndex = getIndexOfTypes(
            orderedLayers.map((layer) => convertStyleTypeToStyleTypeOrder(layer)),
            typesAboveLayer
        );

        // If sliceIndex is -1 then we add to the top of the order
        if (sliceIndex === -1) {
            return [...orderedLayers, layer];
        }
        // Otherwise spilt the ordered layers where the new layer is meant to go and add it
        const layersBelow = orderedLayers.slice(0, sliceIndex);
        const layersAbove = orderedLayers.slice(sliceIndex);
        return [...layersBelow, layer, ...layersAbove];
    }, []);

    // Sort the layers with order value by the order value
    return [
        ...layersWithNegativeMapOrderValue.sort((a, b) => getLayerMapOrderValue(a) - getLayerMapOrderValue(b)),
        ...sortedLayersWithNoMapOrderValue,
        ...layersWithPositiveMapOrderValue.sort((a, b) => getLayerMapOrderValue(a) - getLayerMapOrderValue(b)),
    ];
}

function getIndexOfTypes(layerTypes: StyleTypeOrder[], types: StyleTypeOrder[]) {
    return layerTypes.findIndex((type) => types.includes(type));
}

function convertStyleTypeToStyleTypeOrder(layer: MapModuleLayer) {
    return layer.styleType === StyleType.Area && getStaticStyleValue(layer.areaStyle.dimension) === AreaDimension.Three ? ExtendedStyleType.Area3D : layer.styleType;
}

/** Gets map order value if exists, if null or undefined return 0 */
export const getLayerMapOrderValue = (layer: MapModuleLayer) => (layer?.mapOrder ? layer.mapOrder : 0);

/** Gets the layer which has lowest map order value in the given array */
export function getLowestMapOrderValueLayer(layers: MapModuleLayer[]) {
    const orderedLayers = orderDomainLayers(layers);
    return orderedLayers[0];
}

/** Finds the lowest map order value for a given array of layers and then decreases it by one */
export function getNextLowestLayerMapOrderValue(layers: MapModuleLayer[]) {
    const lowestLayer = getLowestMapOrderValueLayer(layers);
    return lowestLayer != null && getLayerMapOrderValue(lowestLayer) < 0 ? lowestLayer.mapOrder - 1 : -1;
}

/** Gets the layer which has highest map order value in the given array */
export function getHighestMapOrderValueLayer(layers: MapModuleLayer[]) {
    const orderedLayers = orderDomainLayers(layers);
    return orderedLayers[orderedLayers.length - 1];
}

/** Finds the highest map order value for a given array of layers and then increases it by one */
export function getNextHighestLayerMapOrderValue(layers: MapModuleLayer[]) {
    const highestLayer = getHighestMapOrderValueLayer(layers);
    return highestLayer != null && getLayerMapOrderValue(highestLayer) > 0 ? highestLayer.mapOrder + 1 : 1;
}

/** Finds the first layer which is above the new layer style type and then places the new layer below it */
export function getMapOrderForNewLayer(newLayer: DomainLayer, existingLayers: MapModuleLayer[]): number | null {
    // Filter out internal layers from the ordering
    const orderedLayers = orderDomainLayers(existingLayers);
    // Get the style type of the new layer
    const newLayerStyleTypeOrder = convertStyleTypeToStyleTypeOrder(newLayer);
    // Get all the style types above this new layer style type
    const styleTypesAboveNewLayer = styleTypeOrder.slice(styleTypeOrder.findIndex((type) => type === newLayerStyleTypeOrder) + 1);

    // Find the layer which is going to be above the layer being added
    const aboveLayer = orderedLayers.find((layer) => styleTypesAboveNewLayer.includes(convertStyleTypeToStyleTypeOrder(layer)));

    if (aboveLayer == null) {
        // Get the layer which is currently at the top of the order
        const topLayer = orderedLayers[orderedLayers.length - 1];
        // If null there are no other layers on the map, else return the map order value of the top layer + 1
        return topLayer == null ? undefined : getLayerMapOrderValue(topLayer) + 1;
    }

    // Above layer has no map order, then it will automatically order the new layer
    if (aboveLayer.mapOrder == null) {
        return undefined;
    }

    const aboveLayerOrderValue = getLayerMapOrderValue(aboveLayer);
    return aboveLayerOrderValue > 0 ? aboveLayerOrderValue : aboveLayerOrderValue - 1;
}

/** Updates layers with a new map order value, given a new layers map order value */
export function updateExistingLayersMapOrderValue(newLayerOrderValue: number, existingLayers: MapLayer[]): LayerMapOrderUpdate[] {
    if (newLayerOrderValue == null) {
        return [];
    }

    const layersToUpdate: LayerMapOrderUpdate[] = [];

    // If the new layer order value is greater than 0, only need to update layers with a map order value greater than 0
    if (newLayerOrderValue > 0) {
        existingLayers.forEach((layer) => {
            // Update all layers which are greater than or equal to the new layer order value
            if (layer.mapOrder >= newLayerOrderValue) {
                layersToUpdate.push({ id: layer.id, mapOrder: layer.mapOrder + 1 });
            }
        });

        return layersToUpdate;
    }

    // If the new layer order value is less than 0, only need to update layers with a map order value greater than 0
    if (newLayerOrderValue < 0) {
        existingLayers.forEach((layer) => {
            // Update all layers which are less than or equal to the new layer order value
            if (layer.mapOrder <= newLayerOrderValue) {
                layersToUpdate.push({ id: layer.id, mapOrder: layer.mapOrder - 1 });
            }
        });
    }

    return layersToUpdate;
}

/** Assigns map order values to a multiple of new layers and updates the existing layer ids */
export function updateMultipleLayerMapOrderValues(newLayers: DomainLayer[], existingLayers: DomainLayer[]) {
    const layersWithMapOrderValue: DomainLayer[] = JSON.parse(JSON.stringify(existingLayers));

    newLayers.forEach((newLayer) => {
        // For the new layer work out the map order value and the layers which need to be updated
        const newLayerMapOrderValue = getMapOrderForNewLayer(newLayer, layersWithMapOrderValue);
        const updatedMapOrderValues = updateExistingLayersMapOrderValue(newLayerMapOrderValue, layersWithMapOrderValue);

        updatedMapOrderValues.forEach((layerUpdate) => {
            const existingLayerIndex = layersWithMapOrderValue.findIndex((layer) => layer.id === layerUpdate.id);

            // Update the layers with order value with it's new map order
            if (existingLayerIndex !== -1) {
                // If layer exists in the layersWithMapOrderValue array, update the map order value
                layersWithMapOrderValue[existingLayerIndex].mapOrder = layerUpdate.mapOrder;
            }
        });

        // Add the new layer to the existing layers
        newLayer.mapOrder = newLayerMapOrderValue;
        layersWithMapOrderValue.push(newLayer);
    });

    // Get any layer where their map order value has changed
    const layersToUpdate: LayerMapOrderUpdate[] = layersWithMapOrderValue.reduce<LayerMapOrderUpdate[]>((layersToUpdate, layer) => {
        const existingLayer = existingLayers.find((existingLayer) => existingLayer.id === layer.id);
        if ((existingLayer == null || existingLayer.mapOrder !== layer.mapOrder) && layer.mapOrder != null) {
            layersToUpdate.push({ id: layer.id, mapOrder: layer.mapOrder });
        }
        return layersToUpdate;
    }, []);

    return { layersToUpdate, layersWithMapOrderValue };
}
