/* eslint-disable no-console */
import { FeatureCollection } from "@turf/helpers";
import { Point } from "geojson";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { EMPTY_GUID } from "@iventis/utilities";
import { getStaticStyleValue, getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { MapObjectProperties } from "@iventis/map-types";
import { MapObject3D } from "../engine-3d-types";
import { MapObject3DScale, MapObject3DScaleAttribute } from "../engine-3d-scale-types";
import { defaultScale } from "../3d-engine-scale-helpers";
import { ModelLayerStyle } from "../../../../types/models";

export const DEFAULT_LIST_ITEM_ID = "default";

export const get3DMapObjectKey = (modelListItemId: string, colourListItemId: string) => [modelListItemId, colourListItemId].join(",");

export const parseGeoJsonToMapObject3D = (
    geoJson: FeatureCollection<Point, MapObjectProperties>,
    layerId: string,
    layerScaleValue: MapObject3DScale,
    currentLevel: number,
    keys: string[],
    layerName: string,
    datesFilter: boolean,
    modelAttributeId?: string,
    colourAttributeId?: string
): { [layerId: string]: MapObject3D[] } => {
    const DefaultKey = get3DMapObjectKey(DEFAULT_LIST_ITEM_ID, DEFAULT_LIST_ITEM_ID);
    const startingValue = { [DefaultKey]: [] };
    keys.forEach((key) => {
        startingValue[key] = [];
    });

    return geoJson.features.reduce((features, feature) => {
        if (currentLevel === feature.properties.level && (!datesFilter || !feature.properties.dateFiltered)) {
            const modelListItemId = (feature.properties?.[modelAttributeId] as string) ?? DEFAULT_LIST_ITEM_ID;
            const colourListItemId = (feature.properties?.[colourAttributeId] as string) ?? DEFAULT_LIST_ITEM_ID;

            const key = get3DMapObjectKey(modelListItemId, colourListItemId);
            (features[key] ?? features[DefaultKey]).push({
                id: feature.properties.id,
                layerId,
                layerName,
                position: [feature.geometry.coordinates[0], feature.geometry.coordinates[1], 0],
                rotation: feature.properties.rotation,
                properties: feature.properties,
                scale: layerScaleValue?.type === "attribute" ? getAttributeBasedScaleValue(layerScaleValue, feature.properties) : layerScaleValue?.value ?? defaultScale.value,
            });
        }

        return features;
    }, startingValue);
};

export const getAttributeBasedScaleValue = (layerScaleValue: MapObject3DScaleAttribute, properties: MapObjectProperties) => {
    const scaleValue = layerScaleValue.value[(properties[layerScaleValue.dataFieldId] as string) ?? DEFAULT_LIST_ITEM_ID];
    return scaleValue ?? layerScaleValue.value[DEFAULT_LIST_ITEM_ID];
};

export const isStyleAttributeBased = (style: StyleValue<unknown>) => style?.extractionMethod === StyleValueExtractionMethod.Mapped;

export const getFundamentalValueAndListItemId = <TFundamentalType>(style: StyleValue<TFundamentalType>): { listItemId: string; value: TFundamentalType }[] => {
    const mappedValues = Object.entries(style?.mappedValues ?? {}).map(([listItemId, { staticValue }]) => ({ listItemId, value: staticValue }));
    return [...mappedValues, { listItemId: DEFAULT_LIST_ITEM_ID, value: getStaticStyleValueFromMapped(style) }];
};

export const getCustomImageDataFromStyle = async (style: ModelLayerStyle, getAsset: (id: string) => Promise<string>): Promise<Blob | null> => {
    const imageAssetId = getStaticStyleValue(style.customImage);
    if (imageAssetId === EMPTY_GUID || imageAssetId == null) {
        return null;
    }
    // get the image asset from the style
    const imageAsset = await getAsset(imageAssetId);
    const response = await fetch(imageAsset);
    // Make base64 image
    return response.blob();
};
