import { OptionalExceptFor } from "@iventis/types/useful.types";
import { addSecondsToTimeSpan, coordinatesToString, ZERO_TIME_SPAN } from "@iventis/utilities";
import GeoJSON from "geojson";
import { MapObjectProperties, RouteWaypoint } from "@iventis/map-types";
import { RouteFinderResponse } from "../types/internal";

export const createBasicRouteWaypoint = (position: GeoJSON.Position): RouteWaypoint => ({ coordinates: position, name: coordinatesToString(position) });

export const createBasicRouteFinderResponse = (waypoints: OptionalExceptFor<RouteWaypoint, "coordinates">[]): RouteFinderResponse => ({
    route: { type: "LineString", coordinates: waypoints.map((waypoint) => waypoint.coordinates) },
    waypoints: waypoints.map((waypoint) => createBasicRouteWaypoint(waypoint.coordinates)),
    duration: 0,
});

export const generateRouteFeatureAndWaypoints = async (
    routeFinder: () => Promise<RouteFinderResponse>,
    options: {
        preGeneratedFeature: GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties>;
        preGeneratedWaypoints: OptionalExceptFor<RouteWaypoint, "coordinates">[];
        durationProperty?: string;
    }
) => {
    const { route: geometry, waypoints, duration }: RouteFinderResponse =
        options.preGeneratedWaypoints.length < 2 ? createBasicRouteFinderResponse(options.preGeneratedWaypoints) : await routeFinder();

    // Apply the duration and the geometry to the latest transformation

    const feature: GeoJSON.Feature<GeoJSON.LineString, MapObjectProperties> = {
        ...options.preGeneratedFeature,
        properties: {
            ...options.preGeneratedFeature.properties,
            ...(options.durationProperty && { [options.durationProperty]: addSecondsToTimeSpan(ZERO_TIME_SPAN, duration) }),
        },
        geometry,
    };

    return { feature, waypoints } as const;
};
