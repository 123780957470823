/* eslint-disable class-methods-use-this */
import { EventData, Map, MapLayerEventType } from "mapbox-gl";
import { Polygon } from "geojson";
import { BehaviorSubject } from "rxjs";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { MaskLayer } from "../generic-layers/mask-layer";
import { iventisAreaToAggregateLayer } from "../mapbox/engine-mapbox-helpers";
import { SupportedMapboxLayer } from "../mapbox/sublayer-types";
import { MapboxEngineData, MapState } from "../../types/store-schema";

export class MapboxMaskLayer extends MaskLayer {
    private readonly map: Map;

    private mapboxLayers: SupportedMapboxLayer[] = [];

    private mouseOverRef: (ev: MapLayerEventType["mouseover"] & EventData) => void;

    private mouseLeaveRef: (ev: MapLayerEventType["mouseleave"] & EventData) => void;

    private previousCursor: string | null = null;

    constructor(map: Map, polygons: Polygon[], state: BehaviorSubject<MapState<MapboxEngineData>>) {
        super(polygons, state);
        this.map = map;
        this.addSource();
        this.addLayer();
        this.onMouseEvents();
    }

    protected addLayer() {
        this.mapboxLayers = iventisAreaToAggregateLayer(this.maskLayer, false, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, null).map(
            (layer) => layer.layer
        );
        const sitemapSourceNames = this.state.value.engineSpecificData.styles.value.SiteMap.flatMap((siteMap) => Object.keys(siteMap.style.sources));
        const bottomCadLayer = this.map.getStyle().layers.find((layer) => layer.type !== "custom" && typeof layer.source === "string" && sitemapSourceNames.includes(layer.source));

        this.mapboxLayers.forEach((layer) => {
            this.map.addLayer(layer, bottomCadLayer?.id);
        });
    }

    protected removeLayer() {
        this.mapboxLayers.forEach((layer) => {
            if (this.map.getLayer(layer.id)) {
                this.map.removeLayer(layer.id);
            }
        });
    }

    protected addSource() {
        this.map.addSource(this.sourceId, { type: "geojson", data: { type: "FeatureCollection", features: [this.source] } });
    }

    protected removeSource() {
        if (this.map.getSource(this.sourceId)) {
            this.map.removeSource(this.sourceId);
        }
    }

    public updateSource(updatedPolygon: Polygon) {
        super.updateSource(updatedPolygon);
        const source = this.map.getSource(this.sourceId);
        if (source.type === "geojson") {
            source.setData({ type: "FeatureCollection", features: [this.source] });
        }
    }

    protected onMouseEvents() {
        // Add the mouse over event
        this.mouseOverRef = this.mouseOver.bind(this);
        this.map.on("mouseover", this.layerId, this.mouseOverRef);

        // Add the mouse leave event
        this.mouseLeaveRef = this.mouseLeave.bind(this);
        this.map.on("mouseleave", this.layerId, this.mouseLeaveRef);
    }

    private mouseOver() {
        const changeCursor = this.state.value.mapObjectsSelected.value.length !== 0 || this.state.value.mode.value.includes("composition");
        if (changeCursor) {
            this.previousCursor = this.map.getCanvas().style.cursor;
            this.map.getCanvas().style.cursor = "not-allowed";
        }
    }

    private mouseLeave() {
        const currentCursor = this.map.getCanvas().style.cursor;
        // Change cursor back to previous cursor if it was not changed
        if (this.previousCursor != null && currentCursor === "not-allowed") {
            this.map.getCanvas().style.cursor = this.previousCursor;
        }
    }

    public remove() {
        this.removeLayer();
        this.removeSource();
        this.map.off("mouseover", this.layerId, this.mouseOverRef);
        this.map.off("mouseleave", this.layerId, this.mouseLeaveRef);
    }
}
